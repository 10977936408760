//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import constant from '~/const';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        createdFrom: '',
        createdTo: '',
        // currency: [],
      },
      constant,
    };
  },

  computed: {
    ...mapState('statisticsFees', ['filterLists']),
    ...mapGetters('currencies', ['getP2PCurrencyByTitle']),

    // Comment pieces of code can be useful after. In swagger there
    // is a list of currencies, but in task P2PAY-421 there are no requests for this filter

    // priceEquivalentList() {
    //   const { PRICE_EQUIVALENT_CURRENCY_LIST } = constant.currencies;
    //   const list = PRICE_EQUIVALENT_CURRENCY_LIST.map((e) => this.getP2PCurrencyByTitle(e));
    //   return list;
    // },
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },

  async mounted() {
    // await this.getP2PCurrencies();
    // const { PRICE_EQUIVALENT_CURRENCY_LIST } = constant.currencies;
    // const { title } = this.getP2PCurrencyByTitle(PRICE_EQUIVALENT_CURRENCY_LIST[0]);

    const createdFrom = new Date();
    createdFrom.setDate(createdFrom.getDate() - 7); // set createdFrom as one week ago
    const createdFromString = moment(createdFrom).format('YYYY-MM-DD');

    const createdTo = new Date();
    const createdToString = moment(createdTo).format('YYYY-MM-DD');

    this.filters = {
      ...this.filters,
      createdFrom: createdFromString,
      createdTo: createdToString,
      // currency: [title],
    };
  },

  methods: {
    ...mapActions('statisticsFees', ['setFilters']),
    // ...mapActions('currencies', ['getP2PCurrencies']),
  },
};
