export default [
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Currency',
    },
    cell: {
      type: 'text',
      value: (e) => e.currency,
    },
  },
  {
    width: 22,
    header: {
      type: 'text',
      caption: 'Deposit fee',
    },
    cell: {
      type: 'slot',
      name: 'depositFee',
    },
  },
  {
    width: 22,
    header: {
      type: 'text',
      caption: 'Withdrawal fee',
    },
    cell: {
      type: 'slot',
      name: 'withdrawalFee',
    },
  },
  {
    width: 22,
    header: {
      type: 'text',
      caption: 'Blockchain fee',
    },
    cell: {
      type: 'slot',
      name: 'blockchainFee',
    },
  },
  {
    width: 22,
    header: {
      type: 'text',
      caption: 'Trader fee',
    },
    cell: {
      type: 'slot',
      name: 'traderFee',
    },
  },
  {
    width: 22,
    header: {
      type: 'text',
      caption: 'Merchant fee',
    },
    cell: {
      type: 'slot',
      name: 'merchantFee',
    },
  },
  {
    width: 22,
    header: {
      type: 'text',
      caption: 'System fee',
    },
    cell: {
      type: 'slot',
      name: 'systemFee',
    },
  },
];
