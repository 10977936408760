var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section"},[_c('div',[_c('div',{staticClass:"d-flex"},[_c('Filters',{on:{"update":_vm.applyFilters}}),_c('GetReport',{staticClass:"ml-4",attrs:{"type":_vm.reportOperations,"command":_vm.reportData}})],1)]),_c('v-row',[_c('v-col',{staticClass:"flex-grow-0 section__col"},[_c('div',{staticClass:"section__table"},[_c('UiTable',{attrs:{"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader,"per-page":_vm.itemsPerPage,"fixed-header":"","height":"620px"},scopedSlots:_vm._u([{key:"depositFee",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"relative"},[(row.deposits)?_c('div',[_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Avg'))+":")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(row.deposits.avg.toFixed(5)))])])],1),_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Min'))+":")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(row.deposits.min))])])],1),_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Max'))+":")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(row.deposits.max))])])],1),_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Sum'))+":")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(row.deposits.sum))])])],1)],1):_c('p',[_vm._v(" - ")])])]}},{key:"withdrawalFee",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"relative"},[(row.withdrawals)?_c('div',[_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Avg'))+":")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(row.withdrawals.avg.toFixed(5)))])])],1),_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Min'))+":")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(row.withdrawals.min))])])],1),_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Max'))+":")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(row.withdrawals.max))])])],1),_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Sum'))+":")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(row.withdrawals.sum))])])],1)],1):_c('p',[_vm._v(" - ")])])]}},{key:"blockchainFee",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"relative"},[(row.blockchain)?_c('div',[_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Avg'))+":")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(row.blockchain.avg.toFixed(5)))])])],1),_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Min'))+":")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(row.blockchain.min))])])],1),_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Max'))+":")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(row.blockchain.max))])])],1),_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Sum'))+":")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(row.blockchain.sum))])])],1)],1):_c('p',[_vm._v(" - ")])])]}},{key:"traderFee",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"relative"},[(row.traders)?_c('div',[_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Avg'))+":")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(row.traders.avg.toFixed(5)))])])],1),_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Min'))+":")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(row.traders.min))])])],1),_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Max'))+":")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(row.traders.max))])])],1),_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Sum'))+":")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(row.traders.sum))])])],1)],1):_c('p',[_vm._v(" - ")])])]}},{key:"merchantFee",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"relative"},[(row.merchants)?_c('div',[_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Avg'))+":")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(row.merchants.avg.toFixed(5)))])])],1),_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Min'))+":")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(row.merchants.min))])])],1),_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Max'))+":")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(row.merchants.max))])])],1),_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Sum'))+":")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(row.merchants.sum))])])],1)],1):_c('p',[_vm._v(" - ")])])]}},{key:"systemFee",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"relative"},[(row.merchants)?_c('div',[_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Avg'))+":")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(row.system.avg.toFixed(5)))])])],1),_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Min'))+":")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(row.system.min))])])],1),_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Max'))+":")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(row.system.max))])])],1),_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-2",attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Sum'))+":")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v(_vm._s(row.system.sum))])])],1)],1):_c('p',[_vm._v(" - ")])])]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }